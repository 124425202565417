import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams,useSearchParams } from "react-router-dom";
import { GetSingleData, PostData } from "../axios/NetworkCalls";
import { convertDate } from "../utils/helper";
import RequestLoader from "../components/Shared/RequestLoader";
import toast from "react-hot-toast";

function PaymentSuccess() {
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1024px)" });

  const { eventId } = useParams();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [paymentMessage, setPaymentMessage] = useState("");
  const [searchParams] = useSearchParams();
  const paymentKey = searchParams.get("paymentKey");
  const orderId = searchParams.get("orderId");
  const amount = searchParams.get("amount");
  const email = searchParams.get("email");
  const message = searchParams.get("message");
  const name = searchParams.get("name");
  const phoneNumber = searchParams.get("phoneNumber");

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(()=>{
    const PaymentConfirm = async() =>{
      const url = "/api/PaymentConfirm";
      const value = {
        paymentKey:paymentKey,
        orderId:orderId,
        amount:amount
      }
      const r = await PostData(url,value);
      console.log(r);
      if(!r.code) {
        try {
          setLoading(true);

          const values = {
            name: name,
            phoneNumber: phoneNumber,
            email: email,
            message: message,
            paymentMethod:"Toss",
            tossPaymentKey:paymentKey,
          }
    
          const response = await PostData(
            `/api/createEventApplication/${eventId}`,
            values
          );
    
          console.log("response: ", response);
    
          if (response?.status) {
            
          } else { 
            if (response == "You're not logged in. Please login first") {
              //setPreviousRoute("go_back");
              //navigate("/auth/login");
            }
          }
        } catch (error) {
          toast.error(error?.response?.data?.message);
    
          setLoading(true);
        } finally {
          setLoading(false);
        }
        setIsConfirmed(true);
      } else {
        setIsConfirmed(false);
        setPaymentMessage(`${r.message}`);
      }
    }
    PaymentConfirm();
  },[])
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  

  const getData = async () => {
    try {
      setLoading(true);
      const response = await GetSingleData(`/api/getSingleEvent/${eventId}`);

      if (response.success) {
        setData(response?.data);
      } else {
        setError(response?.message);
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };
  if (!isConfirmed) {
    return (
      <div className="w-full min-h-[90vh] flex justify-center items-center">
        <h1 className="text-2xl">{paymentMessage}</h1>
      </div>
    );
  }
  if (loading) {
    return (
      <div className="w-full min-h-[90vh] flex justify-center items-center">
        <RequestLoader size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full min-h-[90vh] flex justify-center items-center">
        <h1 className="text-2xl">{error}</h1>
      </div>
    );
  }

  return (
    <div className="lg:mx-56 my-8 lg:my-16">
      <h1 className="text-[28px] lg:text-[36px] text-center font-semibold mb-8 text-[#00CE3A] whitespace-pre-line">
        결제가 정상적으로 완료 {!isLargeScreen && <br />}
        <span className="text-[#2B2B2B]"> 되었습니다.</span>
      </h1>
      <div className="w-full my-8 custom-shadow-right-bottom lg:rounded-xl overflow-hidden">
        <h1 className="text-[24px] font-bold bg-[#00CE3A] text-white pl-4 lg:pl-12 py-3">
          상품 정보
        </h1>
        <h1 className="lg:hidden text-[24px] font-semibold p-6">
          {data?.productIntroduction}
        </h1>
        <div className="w-full flex flex-col-reverse lg:flex-row justify-between lg:p-12">
          <div className="w-full lg:w-1/2 p-4">
            <h1 className="hidden lg:block text-[24px] font-semibold">
              {data?.productIntroduction}
            </h1>
            <div className="flex flex-col flex-wrap mt-4">
              <div className="w-full flex my-2 px-6 lg:px-0">
                <p className="w-1/2 text-[#00CE3A] text-left">Departure Date</p>
                <p className="w-1/2 text-left">
                  {convertDate(data?.departure)}
                </p>
              </div>
              <div className="w-full flex my-2 px-6 lg:px-0">
                <p className="w-1/2 text-[#00CE3A] text-left">Arrival Date</p>
                <p className="w-1/2 text-left">{convertDate(data?.arrival)}</p>
              </div>
              <div className="w-full flex my-2 px-6 lg:px-0">
                <p className="w-1/2 text-[#00CE3A] text-left">Price</p>
                <p className="w-1/2 text-left">{data?.price} KRW</p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[300px] flex items-center justify-center">
            <img
              src={data?.eventImages[0]}
              alt="Event"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
