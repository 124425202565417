const environment = "production";
//const environment = "production";

let baseUrl;
if (environment === "production") {
  // baseUrl = "https://black-panda-slip.cyclic.app";
  //baseUrl = "http://homebridge-backend-c67d8f57f4c3.herokuapp.com";
  baseUrl = "https://homebackend.co.kr";
} else {
  baseUrl = "http://localhost:5000";
  // baseUrl = "http://192.168.1.108:5000";
}

export default baseUrl;
